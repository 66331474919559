<template>
  <div class="main-account-manager">
    <b-card title="Lịch sử chấm công">
      <b-row>
        <b-col
          lg="4"
          md="4"
          sm="4"
          xs="12"
        >
          <b-form-group>
            <label class="font-weight-bold font-size-label-custom">Phòng ban</label>
            <v-select
              v-model="modelDetailSeach.departmentId"
              placeholder="Chọn phòng ban"
              label="organizationBranchName"
              :options="listDepartment"
              :reduce="(department) => department.id"
              @input="getListDepartmentChildren"
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="4"
          md="4"
          sm="4"
          xs="12"
        >
          <b-form-group v-if="listDepartmentChildren.length > 0 && modelDetailSeach.departmentId">
            <label class="font-weight-bold font-size-label-custom">Khối</label>
            <v-select
              v-model="modelDetailSeach.partId"
              placeholder="Chọn khối"
              label="organizationBranchName"
              :options="listDepartmentChildren"
              :reduce="(part) => part.id"
              @input="getListDepartmentChildrenPart"
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="4"
          md="4"
          sm="4"
          xs="12"
        >
          <b-form-group v-if="listUnitDepartmentChildren.length > 0 && modelDetailSeach.partId">
            <label class="font-weight-bold font-size-label-custom">Tổ</label>
            <v-select
              v-model="modelDetailSeach.unitId"
              placeholder="Chọn khối"
              label="organizationBranchName"
              :options="listUnitDepartmentChildren"
              :reduce="(unit) => unit.id"
              @input="getListEmplyoeeWithUnitId"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          lg="4"
          md="4"
          xs="12"
        >
          <b-form-group>
            <label class="font-weight-bold font-size-label-custom">Nhân viên</label>
            <v-select
              v-model="modelDetailSeach.employeeId"
              label="fullName"
              placeholder="Chọn nhân viên"
              :options="listEmployee"
              :reduce="(employeeId) => employeeId.id"
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="4"
          md="4"
          xs="12"
        >
          <b-form-group>
            <label class="font-weight-bold font-size-label-custom">Đã check in</label>
            <v-select
              v-model="modelDetailSeach.isCheckIn"
              label="label"
              placeholder="Chọn trạng thái check in"
              :options="isCheckInOptions"
              :reduce="(isCheckIn) => isCheckIn.value"
            >
              <span
                slot="no-options"
                @click="$refs.select.open = false"
              >
                Không có dữ liệu
              </span>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col
          lg="4"
          md="4"
          xs="12"
        >
          <b-form-group>
            <label class="font-weight-bold font-size-label-custom">Đi muộn</label>
            <v-select
              v-model="modelDetailSeach.isLate"
              placeholder="Chọn đi muộn"
              label="label"
              :options="isLateOptions"
              :reduce="(isLate) => isLate.value"
            >
              <span
                slot="no-options"
                @click="$refs.select.open = false"
              >
                Không có dữ liệu
              </span>
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          lg="4"
          md="4"
          xs="12"
        >
          <div
            class="time-search"
            @change="onValidateDate"
          >
            <b-row>
              <b-col lg="6">
                <div class="start-date-time">
                  <b-form-group>
                    <label class="font-weight-bold font-size-label-custom">Từ ngày</label>
                    <flat-pickr
                      v-model="modelDetailSeach.start"
                      class="form-control"
                      placeholder="Chọn ngày"
                      :config="configFlatpickr"
                    />
                  </b-form-group>
                </div>
              </b-col>
              <b-col lg="6">
                <div class="end-date-time">
                  <b-form-group>
                    <label class="font-weight-bold font-size-label-custom">Đến ngày</label>
                    <flat-pickr
                      v-model="modelDetailSeach.end"
                      class="form-control"
                      placeholder="Chọn ngày"
                      :config="configFlatpickr"
                    />
                  </b-form-group>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>
        <b-col
          lg="4"
          md="4"
          xs="12"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            class="mr-1 mt-2"
            variant="outline-primary"
            @click="updateDevice"
          >
            <feather-icon
              icon="FilterIcon"
              class="mr-50"
            />
            <span class="align-middle">Lọc</span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            class="mr-1 mt-2"
            variant="outline-primary"
            @click="exportFile"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
            />
            <span class="align-middle">Tạo báo cáo</span>
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12">
          <div class="table-school-block">
            <b-row>
              <b-col
                lg="12"
                style="margin: auto"
              >
                <div class="acction-add-account col-lg-4">
                  <b-row class=" mb-2">
                    <b-col lg="8">
                      <div class="search-account-input">
                        <b-form-input
                          v-model="modelDetailSeach.keyWord"
                          type="text"
                          placeholder="Tìm kiếm"
                          @keyup.enter="updateDevice"
                        />
                      </div>
                    </b-col>
                    <b-col lg="4">
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
                        @click="updateDevice"
                      >
                        <feather-icon
                          icon="SearchIcon"
                          class="mr-50"
                        />
                        <span class="align-middle">Tìm kiếm</span>
                      </b-button>
                    </b-col>
                  </b-row>
                </div>
              </b-col>
            </b-row>
            <span style="color: green">Có tổng số {{ totalCountList }} bản ghi</span>
            <b-table
              responsive
              :items="listQDetail"
              :per-page="perrPage"
              :current-page="currentPage"
              :fields="fields"
            >
              <template #cell(index)="data">
                <span>{{ data.index + 1 }}</span>
              </template>
              <template #cell(status)="data">
                <b-badge
                  v-if="data.value === 0"
                  variant="danger"
                >
                  Không hoạt động
                </b-badge>
                <b-badge
                  v-if="data.value === 1"
                  variant="success"
                >
                  Hoạt động
                </b-badge>
              </template>
              <template #cell(hanhdong)="data">
                <b-button
                  v-b-tooltip.hover.top="'Chi tiết'"
                  variant="flat-primary"
                  class="btn-icon"
                  @click="updateDetail(data.item)"
                >
                  <feather-icon icon="EyeIcon" />
                </b-button>
                <b-button
                  v-b-tooltip.hover.top="'Xác nhận chấm công'"
                  variant="flat-primary"
                  class="btn-icon"
                  @click="deleteDetail(data.item)"
                >
                  <feather-icon icon="CheckIcon" />
                </b-button>
              </template>
            </b-table>
            <template>
              <b-pagination
                v-model="currentPage"
                class="float-right"
                :per-page="perrPage"
                hide-goto-end-buttons
                :total-rows="rows"
              />
            </template>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, VBTooltip, BFormInput, BButton, BTable, BPagination, BBadge, BFormGroup,
} from 'bootstrap-vue'

import { required, max } from '@validations'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'
import { Vietnamese } from 'flatpickr/dist/l10n/vn'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import * as moment from 'moment'
import utils from '@/views/manage-work-shift-branch/utils'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BBadge,
    vSelect,
    flatPickr,
    BFormGroup,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      searchEmId: [],
      isCheckInOptions: [
        { value: null, label: 'Tất cả' },
        { value: true, label: 'Đã check in' },
        { value: false, label: 'Chưa check in' },
      ],
      isLateOptions: [
        { value: null, label: 'Tất cả' },
        { value: true, label: 'Đi muộn' },
      ],
      listEmployee: [],
      listDepartment: [],
      listDepartmentChildren: [],
      listUnitDepartmentChildren: [],
      modelDetailSeach: {
        end: new Date(),
        isCheckIn: null,
        isLate: null,
        organizationBranchId: null,
        start: new Date(),
      },
      timeSpans: [],
      listQDetail: [
      ],
      currentPage: 1,
      perrPage: 10,
      required,
      max,
      totalRecordsList: 0,
      totalCountList: null,
      fields: [
        {
          key: 'index', label: 'Id', thClass: 'custom-th-index',
        },
        {
          key: 'employeeCode', label: 'Mã nhân viên',
        },
        {
          key: 'fullName', label: 'Họ tên',
        },
        {
          key: 'bufferTimeDate', label: 'Ca',
        },
        {
          key: 'date', label: 'Ngày',
        },
        {
          key: 'startHour', label: 'Vào ca',
        },
        {
          key: 'endHour', label: 'Tan ca',
        },
        {
          key: 'actionType', label: 'Loại chấm công',
        },
        {
          key: 'hanhdong', label: 'Hành động', thClass: 'custom-th-action-account', tdClass: 'custom-td-action-account',
        },
      ],
      listPerPage: [
        3, 5, 10,
      ],
      configFlatpickr: {
        wrap: true,
        altFormat: 'd/m/Y',
        altInput: true,
        locale: Vietnamese,
        allowInput: true,
      },
    }
  },
  computed: {
    rows() {
      if (this.listQDetail) {
        return this.listQDetail.length
      }
    },
  },
  created() {
    const timeDuration = 30; const timeSpans = []
    for (let i = 0; i < 24 * 60; i += timeDuration) {
      timeSpans.push({ value: i, label: utils.minuteToHour(i) })
    }
    this.timeSpans = timeSpans
    this.getListAllDepartment()
  },
  methods: {
    exportFile() {
      const exportInfo = {
        organizationBranchId: this.modelDetailSeach.organizationBranchId,
        employeeId: this.modelDetailSeach.employeeId ? [this.modelDetailSeach.employeeId] : [],
        start: this.modelDetailSeach.start,
        end: this.modelDetailSeach.end,
        isCheckIn: this.modelDetailSeach.isCheckIn,
        isLate: this.modelDetailSeach.isLate,
      }
      this.$crm.post('time-keeping/create', exportInfo).then(res => {
        window.open(`${'http://103.171.92.154:8684/crm/file/'}${res.data.fileName}?access_token=${localStorage.getItem('access_token')}`, '_blank')
      })
    },
    updateDevice() {
      const searchInfo = {
        employeeId: this.modelDetailSeach.employeeId ? [this.modelDetailSeach.employeeId] : [],
        end: this.modelDetailSeach.end,
        isCheckIn: this.modelDetailSeach.isCheckIn,
        isLate: this.modelDetailSeach.isLate,
        organizationBranchId: this.modelDetailSeach.organizationBranchId,
        pageIndex: this.modelDetailSeach.pageIndex,
        start: this.modelDetailSeach.start,
        keyWord: this.modelDetailSeach.keyWord,
      }
      this.$crm.post('time-keeping/find', searchInfo).then(res => {
        this.listQDetail = res.data
        res.data.map(itemTime => {
          itemTime.startHour = this.timeSpans.find(timeSpan => timeSpan.value === itemTime.startHour).label
          itemTime.endHour = this.timeSpans.find(timeSpan => timeSpan.value === itemTime.endHour).label
        })
        this.totalCount = this.listQDetail.length
        for (let i = 0; i < this.listQDetail.length; i++) {
          this.listQDetail[i].createdDate = moment(this.listQDetail[i].createdDate).format('DD-MM-YYYY')
        }
      })
    },
    onValidateDate() {
      this.listHistoryItem = []
      if (this.modelDetailSeach.end !== '' && this.modelDetailSeach.start !== '') {
        const startDate = new Date(this.modelDetailSeach.start)
        const endDate = new Date(this.modelDetailSeach.end)
        if (startDate > endDate) {
          this.isValidateDate = true
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Lỗi!',
              icon: 'XIcon',
              variant: 'danger',
              text: 'Từ ngày phải nhỏ hơn đến ngày',
            },
          })
        }
      }
    },
    getListAllDepartment() {
      this.modelDetailSeach.departmentId = null
      this.modelDetailSeach.partId = null
      this.modelDetailSeach.unitId = null
      this.listDepartmentChildren = []
      this.listUnitDepartmentChildren = []
      this.$crm.get('organization-branch-school/find-all-department').then(res => {
        this.listDepartment = res.data
        this.getListEmployee()
      })
    },
    getListDepartmentChildren(id) {
      this.listUnitDepartmentChildren = []
      this.modelDetailSeach.partId = null
      this.modelDetailSeach.organizationBranchId = id
      this.modelDetailSeach.pageIndex = 1
      if (id) {
        this.$crm.get(`organization-branch-school/find-children/${id}`).then(res => {
          this.listDepartmentChildren = res.data
          this.getListEmployee(id)
        })
      } else {
        this.getListEmployee(id)
      }
    },
    getListDepartmentChildrenPart(id) {
      this.modelDetailSeach.pageIndex = 1
      this.modelDetailSeach.organizationBranchId = id
      this.modelDetailSeach.unitId = null
      if (id) {
        this.$crm.get(`organization-branch-school/find-children/${id}`).then(res => {
          this.listUnitDepartmentChildren = res.data
          this.getListEmployee(id)
        })
      } else {
        this.modelDetailSeach.organizationBranchId = this.modelDetailSeach.departmentId
        this.getListEmployee(this.modelDetailSeach.organizationBranchId)
      }
    },
    getListEmplyoeeWithId(id) {
      if (id) {
        this.modelDetailSeach.employeeId = [id]
      } else {
        this.modelDetailSeach.employeeId = this.modelDetailSeach.employeeId
      }
    },
    getListEmplyoeeWithUnitId(id) {
      if (id) {
        this.modelDetailSeach.organizationBranchId = id
        this.getListEmployee(id)
      } else {
        this.modelDetailSeach.organizationBranchId = this.modelDetailSeach.partId
        this.getListEmployee(this.modelDetailSeach.organizationBranchId)
      }
    },
    getListEmployee(id) {
      if (id) {
        this.listEmployee = []
        this.$crm.get(`employee-school/get-all/?organizationBranchSchoolId=${id}`)
          .then(res => {
            if (res.data.length > 0) {
              this.listEmployee = res.data
            } else {
              this.listEmployee = []
              this.modelDetailSeach.employeeId = null
            }
          })
      } else {
        this.updateDevice()
      }
    },
    updateDetail(item) {
      this.$router.push({
        name: 'time-keeping-detail',
        query: {
          id: item.employeeId,
        },
      })
    },
    deleteDetail(item) {
      this.$swal({
        title: 'Xác nhận chấm công',
        text: `Bạn có muốn xác nhận chấm công cho ${item.fullName}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Đồng ý',
        cancelButtonText: 'Hủy bỏ',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const payload = {
            employeeId: item.employeeId,
            createdAt: new Date(),
            imageUrl: null,
          }
          this.$crm.post('time-keeping/update-time-keeping', payload).then(res => {
            this.updateDevice()
            this.$swal({
              icon: 'success',
              title: 'Xác nhận chấm công!',
              text: 'Xác nhận chấm công thành công',
              showConfirmButton: false,
              timer: 1500,
            })
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '../../@core/scss/custom/account';
@import "~ag-grid-community/dist/styles/ag-grid.scss";
@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";
@import 'src/@core/scss/vue/libs/vue-select.scss';
@import 'src/@core/scss/vue/libs/vue-sweetalert.scss';
@import 'src/@core/scss/vue/libs/vue-flatpicker.scss';
</style>
